import React, { useEffect } from "react";
import classes from "./Privacy.module.css";
import Footer from "../../Components/Footer/Footer";
const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={classes.privacy}>
        <h1>Disclaimers & Dispute Resolution</h1>

        <h2>Chatakk Disclaimer</h2>
        <h3>
          Chatakk is not responsible for any incorrect or inaccurate content
          posted on the Website or in connection with Services, whether caused
          by Chatakk, Members, or by any of the equipment or programming
          associated with or utilized by Borzo India Private Limited. Under no
          circumstances will Chatakk or any of its affiliates, advertisers,
          promoters or distribution partners be responsible for any loss or
          damage, including personal injury or death, resulting from anyone’s
          use of the Services, any content posted on the Website or transmitted
          to Members, or any interactions between Members, whether online or
          offline. Services are provided “AS-IS” and Chatakk India Private
          Limited expressly disclaims any warranty of fitness for a particular
          purpose or non-infringement. Chatakk India Private Limited cannot
          guarantee and does not promise any specific results from use of
          Services.
        </h3>

        <h2>Dispute Resolution:</h2>
        <h3>
          These Terms of Use shall be governed in all respects by the laws of
          India and you agree to submit to the exclusive jurisdiction of the
          courts in New Delhi.
        </h3>
        <h3>
          Chatakk’s failure to exercise or enforce any right or provision of
          these Terms of Use shall not constitute a waiver of such right or
          provision and does not waive the right to act with respect to
          subsequent or similar breaches.
        </h3>
        <h3>
          In the event of any dispute or difference of opinion between the
          parties arising out of or in connection with this Agreement or with
          regard to performance of any obligations by either party, the parties
          hereto shall use their best efforts to settle such disputes or
          differences of opinion amicably by mutual negotiation. However, if the
          same still remains unresolved, then such dispute, difference or
          question in respect of this Agreement or the subject matter thereof,
          shall be referred to for the decision of Arbitrators appointed by
          Dayal legal associates. The Award of the Arbitrators shall be final
          and binding on the parties and the provisions of the Indian
          Arbitration and Conciliation Act, 1996 or any statutory modifications
          or re-enactment thereof shall apply to such Arbitration.
        </h3>
        <h3>
          If a court of competent jurisdiction holds any provisions of these
          Terms of Use to be invalid or unenforceable, the remaining provisions
          will remain in full force and effect and be construed so as to be
          enforceable to the maximum extent permissible by law.
        </h3>
        <h3>
          The section headings contained in these Terms of Use are for reference
          purposes only and shall not in any way affect the meaning or
          interpretation of these Terms of Use.
        </h3>
        <h3>
          If you have any queries regarding these Terms of Use, please contact
          Chatakk at support@chatakk.com.
        </h3>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
