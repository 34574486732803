import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../Components/Footer/Footer";
import classes from "./Contact.module.css";
import help from "../../Assets/Help.webp";
import service from "../../Assets/Services.webp";
import { loginActions } from "../../Store/login";

const Contact = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    // console.log(userPresent);
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);
  return (
    <>
      <h2 className={classes.heading}>Customer Query Form</h2>

      <div className={classes.contactUs}>
        <div className={classes.clientService}>
          <img src={help} alt="Customer Help" />
        </div>
        <div className={classes.contactForm}>
          {/* <h2>Contact Us</h2> */}
          <p>
            For any queries or complaints, fill out the form and we will contact
            you ASAP.
          </p>
          <form
            action="https://formsubmit.co/chatakkdelivery@gmail.com"
            method="POST"
          >
            <input
              type="text"
              id="text"
              name="name"
              placeholder="Your Name"
              required
            />
            <input
              type="number"
              id="number"
              name="number"
              placeholder="Your Number"
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              placeholder="Enter Your Query/Complaints"
              required
            />
            <div className={classes.checkBox}>
              <input type="checkbox" required />{" "}
              <span>
                By checking this box I confirm that I have read and understood
                Privacy Policy and I agree to let Chatakk process my information
                in accordance with the terms and conditions outlined in it.
              </span>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>

      <h2 className={classes.heading}>Business Query Form </h2>
      <div className={classes.contactUs}>
        <div className={classes.contactForm}>
          {/* <h2>Contact Us</h2> */}
          <p>
            Fill out this form to schedule a free consultation on delivery
            options for your business.
          </p>
          <form
            action="https://formsubmit.co/chatakkdelivery@gmail.com"
            method="POST"
          >
            <input
              type="text"
              id="text"
              name="name"
              placeholder="Your Name"
              required
            />
            <input
              type="number"
              id="number"
              name="number"
              placeholder="Your Number"
              required
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <input
              type="text"
              id="text"
              name="Company Name"
              placeholder="Company Name"
              required
            />
            <input
              type="text"
              id="text"
              name="Company Profile"
              placeholder="Company Business Profile"
              required
            />
            <input
              type="text"
              id="text"
              name="quantity"
              placeholder="Quantity of Order Planned Per Month"
              required
            />
            <div className={classes.checkBox}>
              <input type="checkbox" required />{" "}
              <span>
                By checking this box I confirm that I have read and understood
                Privacy Policy and I agree to let Chatakk process my information
                in accordance with the terms and conditions outlined in it.
              </span>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className={classes.clientService}>
          <img src={service} alt="Customer Help" />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
