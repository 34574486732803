import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import classes from "./Order.module.css";
import AllOrders from "./AllOrders";
import Footer from "../../Components/Footer/Footer";

const Print = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className={classes.print}>
        <button type="button" onClick={handlePrint}>
          Print Order History
        </button>
        <AllOrders ref={componentRef} />
      </div>
      <Footer />
    </>
  );
};

export default Print;
