import eCommerce from "../../Assets/ecommerce.webp";
import groceries from "../../Assets/groceries.webp";
import pharma from "../../Assets/pharma.webp";
import fashion from "../../Assets/fashion.webp";
import restaurants from "../../Assets/restaurants.webp";
import offices from "../../Assets/offices.webp";

export const allTypes = [
  {
    key: 1,
    id: "Web",
    image: eCommerce,
  },

  {
    key: 2,
    id: "Web",
    image: groceries,
  },
  {
    key: 4,
    id: "UI",
    image: pharma,
  },

  {
    key: 5,
    id: "UI",
    image: fashion,
  },

  {
    key: 6,
    id: "UI",
    image: restaurants,
  },
  {
    key: 7,
    id: "UX",
    image: offices,
  },
];
