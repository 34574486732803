import React, { useState } from "react";
import classes from "./Faq.module.css";
import { BsArrowDownCircle } from "react-icons/bs";
import { BsArrowUpCircle } from "react-icons/bs";

const Faq = () => {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);

  const faq01 = () => {
    setFaq1(!faq1);
  };
  const faq02 = () => {
    setFaq2(!faq2);
  };
  const faq03 = () => {
    setFaq3(!faq3);
  };
  const faq04 = () => {
    setFaq4(!faq4);
  };

  return (
    <div className={classes.faqSection}>
      <h2>FAQ's</h2>
      <div className={classes.faq} onClick={faq01}>
        <div className={classes.faqHead}>
          <h3>What is Chatakk?</h3>
          <div className={classes.openClose}>
            {faq1 && (
              <span>
                <BsArrowUpCircle size={25} />
              </span>
            )}
            {!faq1 && (
              <span>
                <BsArrowDownCircle size={25} />
              </span>
            )}
          </div>
        </div>
        {faq1 && (
          <p>
            Chatakk is an online Logistics Service which delivers your parcels
            in same day in minimal cost. You have an online business or
            restaurants or any other shop, just place the order and we will take
            care of your delivery. Currently Chatakk is only available in
            Kolkata.
          </p>
        )}
      </div>
      <div className={classes.faq} onClick={faq02}>
        <div className={classes.faqHead}>
          <h3>How to Place an Order?</h3>
          <div className={classes.openClose}>
            {faq2 && (
              <span>
                <BsArrowUpCircle size={25} />
              </span>
            )}
            {!faq2 && (
              <span>
                <BsArrowDownCircle size={25} />
              </span>
            )}
          </div>
        </div>
        {faq2 && (
          <p>
            To Place an order, you can follow these steps:
            <br />
            <br />
            1) Signup or Login with your Email and Password.
            <br />
            2) Click on 'Order Now!' button in Top bar.
            <br />
            3) Fill out all the details in the 'Order Page'. Then, Click on 'See
            Price' button to see the cost of your delivery.
            <br />
            4) After seeing the price, click on 'Submit Order' button to place
            your order.
          </p>
        )}
      </div>
      <div className={classes.faq} onClick={faq03}>
        <div className={classes.faqHead}>
          <h3>How to Track my Orders?</h3>
          <div className={classes.openClose}>
            {faq3 && (
              <span>
                <BsArrowUpCircle size={25} />
              </span>
            )}
            {!faq3 && (
              <span>
                <BsArrowDownCircle size={25} />
              </span>
            )}
          </div>
        </div>
        {faq3 && (
          <p>
            First, you have to login with your email and password, then click on
            'History' button in top bar to see your order history.
          </p>
        )}
      </div>
      <div className={classes.faq} onClick={faq04}>
        <div className={classes.faqHead}>
          <h3>How to Install Chatakk App?</h3>
          <div className={classes.openClose}>
            {faq4 && (
              <span>
                <BsArrowUpCircle size={25} />
              </span>
            )}
            {!faq4 && (
              <span>
                <BsArrowDownCircle size={25} />
              </span>
            )}
          </div>
        </div>
        {faq4 && (
          <p>
            There are many ways to Install Chatakk App
            <br />
            <br />
            (A) For Mobile Users:
            <br />
            1. Open our website Chatakk.com and you will see a popup which says
            'Add Chatakk to Home Screen'. Click on it and install it.
            <br />
            Or
            <br />
            2. Open our website Chatakk.com and click on the three dots in your
            browser. And in that section you will find 'Install App'. Click on
            it and you are good to go.
            <br />
            <br />
            (B) For Desktop Users:
            <br />
            1. Open our website Chatakk.com and in the url section you will find
            a download icon. Click on it and the app will be install in your
            Desktop.
          </p>
        )}
      </div>
    </div>
  );
};

export default Faq;
