import React, { useState, useEffect } from "react";
import classes from "./Welcome.module.css";
import { fire } from "../Login/Google";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BsPersonCircle, BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { loginActions } from "../../Store/login";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";

const Welcome = React.forwardRef((props, ref) => {
  const [data, setData] = useState({});
  const [activeData, setActiveData] = useState({});
  const [userData, setUserData] = useState({});
  const [currentUser, setCurrentUser] = useState([]);
  const [deliverOrders, setDeliverOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [showActive, setShowActive] = useState(true);

  const user = useSelector((state) => state.loggedIn.username);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    // console.log(userPresent);
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  useEffect(() => {
    fire.child("activeOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setActiveData({ ...snapshot.val() });
      } else {
        setActiveData({});
      }
    });
    return () => {
      setActiveData({});
    };
  }, []);

  useEffect(() => {
    fire.child("allOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  useEffect(() => {
    fire.child("signupUsers").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setUserData({ ...snapshot.val() });
      } else {
        setUserData({});
        toast.error("No Data found");
      }
    });
    return () => {
      setUserData({});
    };
  }, []);

  useEffect(() => {
    const filteredUser = Object.values(userData).filter((filterOrder) => {
      return filterOrder.email === user;
    });
    setCurrentUser(filteredUser);
  }, [userData, user]);

  useEffect(() => {
    const filteredOrder = Object.values(activeData).filter((filterOrder) => {
      return filterOrder.userEmail === user;
    });
    setActiveOrders(filteredOrder);
  }, [activeData, user]);

  useEffect(() => {
    const filteredOrder = Object.values(data).filter((filterOrder) => {
      return filterOrder.userEmail === user;
    });
    setDeliverOrders(filteredOrder);
  }, [data, user]);

  const reverseArrayList = (arr) => {
    arr.reverse();
    return arr;
  };

  const reverseActiveOrders = reverseArrayList(activeOrders);
  const reversedDeliveredOrders = reverseArrayList(deliverOrders);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.profile}>
          <h3>Welcome</h3>
          {currentUser.map((id) => {
            return (
              <div key={id} className={classes.profileInfo}>
                <p>
                  <BsPersonCircle /> &nbsp;
                  {id.name}
                </p>
                <p>
                  <MdEmail /> &nbsp;
                  {id.email}
                </p>
                <p>
                  <BsFillTelephoneFill /> &nbsp;
                  {id.number}
                </p>
              </div>
            );
          })}
          <Link to="/print">
            <button>Print Order History</button>
          </Link>
        </div>

        <div className={classes.orderStatus}>
          <div className={classes.statusTypes}>
            <h3
              onClick={() => setShowActive(true)}
              style={{
                borderBottom: showActive ? "2px solid #ff4141" : "none",
                width: "fit-content",
              }}
            >
              Active
            </h3>
            <h3
              style={{
                borderBottom: !showActive ? "2px solid #ff4141" : "none",
                width: "fit-content",
              }}
              onClick={() => setShowActive(false)}
            >
              Delivered
            </h3>
          </div>

          {showActive && activeOrders.length !== 0 && (
            <>
              <div className={classes.profile1}>
                <h3>{activeOrders.length} Orders Active</h3>
              </div>
              <div className={classes.container1}>
                {reverseActiveOrders.map((id) => {
                  return (
                    <div className={classes.orderContainer} key={id}>
                      <div className={classes.idStatus}>
                        <div>
                          Order Id -{" "}
                          <span style={{ color: "#ff4141" }}>{id.orderId}</span>
                        </div>
                        <div className={classes.riderStatus}>
                          <div>
                            {id.rider === "Express Rider" ? "🧍🏻" : "🧍🏻🧍🏻"}
                          </div>
                          <div
                            className={`${classes.pickStatus} ${
                              id.status === "Placed"
                                ? classes.blue
                                : classes.green
                            }`}
                          ></div>
                        </div>
                      </div>
                      <div className={classes.pickupContainer}>
                        <span
                          style={{
                            fontSize: "large",
                          }}
                        >
                          ⬆️{" "}
                        </span>
                        {id.pickAdd} --- <strong>{id.pickNo}</strong>
                      </div>
                      <br />
                      <br />
                      <div className={classes.dropContainer}>
                        <span
                          style={{
                            fontSize: "large",
                          }}
                        >
                          ⬇️{" "}
                        </span>{" "}
                        {id.dropAdd} --- <strong>{id.dropNo}</strong>
                        <br />
                        <br />
                        {id.dropAdd1} <strong>{id.dropNo1}</strong>
                        <br />
                        <br />
                        {id.dropAdd2} <strong>{id.dropNo2}</strong>
                      </div>
                      <div className={classes.accessContainer}>
                        <div className={classes.priceContainer}>
                          {/* {id.rider} */}
                          {/* <br /> */}
                          Rs {id.price}
                        </div>
                        <div className={classes.priceContainer}>
                          {/* {id.status} */}
                          {/* <br /> */}
                          {id.deliveryBoy}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {showActive && activeOrders.length === 0 && (
            <h2>No Active Orders Found.</h2>
          )}
          {!showActive && deliverOrders.length === 0 && (
            <h2>No Delivered Orders Found.</h2>
          )}
          {!showActive && deliverOrders.length !== 0 && (
            <>
              <div className={classes.profile1}>
                <h3>{deliverOrders.length} Orders Delivered</h3>
              </div>
              <div className={classes.container1}>
                {reversedDeliveredOrders.map((id) => {
                  return (
                    <div className={classes.orderContainer} key={id}>
                      <div className={classes.accessContainer}>
                        <div className={classes.priceContainer}>
                          <span style={{ color: "black" }}>Order Id - </span>{" "}
                          {id.orderId}
                        </div>
                        <div className={classes.priceContainer}>
                          Rs {id.price}
                        </div>
                      </div>
                      <div className={classes.pickupContainer}>
                        <span
                          style={{
                            fontSize: "large",
                          }}
                        >
                          ⬆️{" "}
                        </span>
                        {id.pickAdd} --- <strong>{id.pickNo}</strong>
                      </div>
                      <br />
                      <br />
                      <div className={classes.dropContainer}>
                        <span
                          style={{
                            fontSize: "large",
                          }}
                        >
                          ⬇️{" "}
                        </span>{" "}
                        {id.dropAdd} --- <strong>{id.dropNo}</strong>
                        <br />
                        <br />
                        {id.dropAdd1} <strong>{id.dropNo1}</strong>
                        <br />
                        <br />
                        {id.dropAdd2} <strong>{id.dropNo2}</strong>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
});

export default Welcome;
