import React, { useEffect } from "react";
import classes from "./Privacy.module.css";
import Footer from "../../Components/Footer/Footer";

const Community = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={classes.privacy}>
        <h1>Community Guidelines</h1>
        <h2>Introduction</h2>
        <h3>
          While technology helps ensure the speed, dependability, and safety of
          our service, people matter most to the Chatakk experience.
        </h3>
        <h2>Professionalism & Respect</h2>
        <h3>
          Chatakk maintains a zero-tolerance policy regarding all forms of
          discrimination, harassment or abuse.
        </h3>
        <h2>Non-discrimination</h2>
        <h3>
          It is unacceptable to refuse to provide or accept services based on a
          person’s race, religion, national origin, disability, sexual
          orientation, sex, marital status, gender identity, age or any other
          characteristic protected under applicable Indian law. This type of
          behavior can result in permanent loss of access to the Chatakk
          platform.
        </h3>
        <h2>No inappropriate behavior</h2>
        <h3>
          Customers and Couriers expect each other to act appropriately and
          respect boundaries. It is disrespectful to make derogatory remarks
          about a person or group. Furthermore, commenting on appearance, asking
          overly personal questions and making unwanted physical contact are all
          considered inappropriate behaviors. Anyone who solicits or engages in
          sexual conduct while using the Chatakk platform may lose future access
          to Chatakk. We encourage you to be mindful of other users’ privacy and
          personal space. Violence of any kind will not be tolerated.
        </h3>
        <h2>Compliance with the law</h2>
        <h3>
          Illegal substances and – unless explicitly allowed by law – open
          containers of alcohol are not permitted to be requested for delivery.
          This is against the law and a serious violation of Chatakk’s policy.
          Furthermore, Chatakk does not tolerate drug or alcohol use by Couriers
          while using the Chatakk app. If a Client believes a Courier may be
          under the influence of drugs or alcohol, please request that the
          Courier to cancel delivery request immediately and alert Chatakk
          Support at help https://chatakk.com. Chatakk prohibits human
          trafficking of any kind, including the commercial sexual exploitation
          of children, while using our app. Anyone who engages in such activity
          may lose access to Chatakk.
        </h3>
        <h2>Disabilities</h2>
        <h3>
          Chatakk expects compliance with all state, and local laws governing
          the delivery of package with disabilities. Violation of these laws,
          constitutes a breach of the parties’ licensing agreement.
        </h3>
        <h2>Following the rules</h2>
        <h3>
          We require partners to keep documents up to date to remain active.
          Couriers, likewise, must maintain active payment information.
        </h3>
        <h3>
          Couriers are responsible for guests traveling with them or anyone they
          request a ride for. It is your responsibility to ensure everyone
          adheres to Chatakk’s Community Guidelines.
        </h3>
        <h3>
          Violations of this Community Guidelines could result in loss of your
          Chatakk Account. Please report any violations to Chatakk, we want to
          hear your feedback! Alert us and we’ll take action!
        </h3>
        <h2>Emergencies</h2>
        <h3>
          If at any time you feel that you are faced with a situation that
          requires immediate emergency attention, please call the emergency
          service number in your area. Once all parties are safe and the
          situation has been handled by the authorities, please then notify
          Chatakk. We will assist and take appropriate action as needed,
          including cooperating with law enforcement.
        </h3>
      </div>
      <Footer />
    </>
  );
};

export default Community;
