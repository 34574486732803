import React, { useState, useRef, useEffect } from "react";
import classes from "./Order.module.css";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../Components/Footer/Footer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fire } from "../Login/Google";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { loginActions } from "../../Store/login";
import "../../App.css";
import { FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const Order = () => {
  const user = useSelector((state) => state.loggedIn.username);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  const [libraries] = useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [submitOrder, setSubmitOrder] = useState(false);
  // const [clicked0, setClicked0] = useState(false);
  // const [clicked1, setClicked1] = useState(false);
  // const [clicked2, setClicked2] = useState(false);
  const [secureValue, setSecureValue] = useState(0);
  const [userData, setUserData] = useState({});
  const [myArray, setMyArray] = useState([]);
  const [couponData, setCouponData] = useState({});
  const [myCoupon, setMyCoupon] = useState([]);
  const [solo, setSolo] = useState(false);
  const [duo, setDuo] = useState(false);
  // const [pickup, setPickup] = useState(false);
  // const [drop, setDrop] = useState(false);
  const [multipleDropLocation1, setMultipleDropLocation1] = useState(false);
  const [multipleDropLocation2, setMultipleDropLocation2] = useState(false);
  const [kilometers, setKilometers] = useState(0);
  const [notification, setNotification] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {
    fire.child("dynamicUpdate").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setUserData({ ...snapshot.val() });
      } else {
        setUserData({});
      }
    });
    return () => {
      setUserData({});
    };
  }, []);

  useEffect(() => {
    const filteredUser = Object.values(userData).filter((filterOrder) => {
      return filterOrder.price !== 100;
    });
    setMyArray(filteredUser);
  }, [userData]);

  useEffect(() => {
    fire.child("coupons").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setCouponData({ ...snapshot.val() });
      } else {
        setCouponData({});
      }
    });
    return () => {
      setCouponData({});
    };
  }, []);

  useEffect(() => {
    const filteredCoupon = Object.values(couponData).filter((filterOrder) => {
      return filterOrder.coupon !== "";
    });
    setMyCoupon(filteredCoupon);
  }, [couponData]);

  // const selectedValueRef = useRef();
  const rider = useRef();
  const pickAdd = useRef();
  const pickNo = useRef();
  const pickRem = useRef();
  const dropAdd = useRef();
  const dropNo = useRef();
  const dropRem = useRef();
  const dropAdd1 = useRef();
  const dropNo1 = useRef();
  const dropRem1 = useRef();
  const dropAdd2 = useRef();
  const dropNo2 = useRef();
  const dropRem2 = useRef();
  const secPack = useRef();
  const packRef = useRef();
  // const payment = useRef();
  const coupon = useRef();

  const navigate = useNavigate();
  const changePickupAddress = () => {
    setSubmitOrder(false);
  };
  const numberLength = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
    if (e.target.value.trim().length === 10) {
      return toast.error("Enter Valid Number");
    }
  };

  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);

  const [price, setPrice] = useState();
  const [clicked, setClicked] = useState("");

  if (!isLoaded) {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  const checkClick = (e) => {
    setClicked(e.target.textContent);
  };
  const changeClick = (e) => {
    setClicked(e.target.value);
  };

  // const weightContainerClick = (event) => {
  //   const { tagName, textContent } = event.target;
  //   if (tagName === "P") {
  //     selectedValueRef.current = textContent;
  //   }
  // };
  // const weight1kg = () => {
  //   setClicked0(!clicked0);
  //   setClicked1(false);
  //   setClicked2(false);
  // };
  // const weight5kg = () => {
  //   setClicked1(!clicked1);
  //   setClicked0(false);
  //   setClicked2(false);
  // };
  // const weight10kg = () => {
  //   setClicked2(!clicked2);
  //   setClicked0(false);
  //   setClicked1(false);
  // };
  let distance1 = 0;
  let distance2 = 0;
  let initial = 0;
  let prices;
  let newUpdatedDistance;

  async function calculateRoute() {
    if (!solo && !duo) {
      return toast.error("Select Solo or Duo Rider Option");
    }

    if (pickAdd.current.value === "") {
      return toast.error("Enter Correct Pickup Address");
    }
    if (dropAdd.current.value === "") {
      return toast.error("Enter Correct Delivery Address");
    }
    if (multipleDropLocation1) {
      if (dropAdd1.current.value === "") {
        return toast.error("Enter Multiple Delivery Address");
      }
    }
    if (multipleDropLocation2) {
      if (dropAdd2.current.value === "") {
        return toast.error("Enter Multiple Delivery Address");
      }
    }
    if (secPack.current.value === "") {
      return toast.error("Enter a value to secure your package");
    }

    setButtonLoader(true);

    const shortestDistance = [];
    const pickupShortDistance = [];

    function findLowestNumber(arr) {
      if (arr.length === 0) {
        return undefined;
      }

      let lowest = arr[0];

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] < lowest) {
          lowest = arr[i];
        }
      }

      return lowest;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    // Pickup Point Distance

    const initialResults = await directionsService.route({
      origin:
        "16/h/3, Bright Street, Park Circus, Ballygunge, Kolkata, West Bengal",
      destination: pickAdd.current.value,
      provideRouteAlternatives: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    for (let i = 0; i < initialResults.routes.length; i++) {
      pickupShortDistance.push(initialResults.routes[i].legs[0].distance.value);
    }

    const pickLowestNumber = findLowestNumber(pickupShortDistance);
    const pickMToKm = pickLowestNumber / 1000;
    const initialUpdatedDistance = pickMToKm.toFixed(1);

    if (initialUpdatedDistance > 3 && initialUpdatedDistance < 5.3) {
      initial = 7;
    } else if (initialUpdatedDistance > 5.3 && initialUpdatedDistance < 7.3) {
      initial = 12;
    } else if (initialUpdatedDistance > 7.3 && initialUpdatedDistance < 10.3) {
      initial = 18;
    } else if (initialUpdatedDistance > 10.3 && initialUpdatedDistance < 15.3) {
      initial = 25;
    } else if (initialUpdatedDistance > 15.3 && initialUpdatedDistance < 20.3) {
      initial = 40;
    } else if (initialUpdatedDistance > 20.3) {
      initial = 50;
    }

    // Distance Calculator

    const results = await directionsService.route({
      origin: pickAdd.current.value,
      destination: dropAdd.current.value,
      provideRouteAlternatives: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    for (let i = 0; i < results.routes.length; i++) {
      shortestDistance.push(results.routes[i].legs[0].distance.value);
    }

    const lowestNumber = findLowestNumber(shortestDistance);
    const mToKm = lowestNumber / 1000;
    const roundOff = mToKm.toFixed(1);
    const updatedDistance = +roundOff;

    if (multipleDropLocation1) {
      const shortestDistance = [];

      const results = await directionsService.route({
        origin: dropAdd.current.value,
        destination: dropAdd1.current.value,
        provideRouteAlternatives: true,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      for (let i = 0; i < results.routes.length; i++) {
        shortestDistance.push(results.routes[i].legs[0].distance.value);
      }

      const lowestNumber = findLowestNumber(shortestDistance);
      const mToKm = lowestNumber / 1000;
      const roundOff = mToKm.toFixed(1);
      const updatedDistance = +roundOff;
      distance1 = updatedDistance + 1;
    }

    if (multipleDropLocation2) {
      const shortestDistance = [];

      const results = await directionsService.route({
        origin: dropAdd1.current.value,
        destination: dropAdd2.current.value,
        provideRouteAlternatives: true,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      for (let i = 0; i < results.routes.length; i++) {
        shortestDistance.push(results.routes[i].legs[0].distance.value);
      }

      const lowestNumber = findLowestNumber(shortestDistance);
      const mToKm = lowestNumber / 1000;
      const roundOff = mToKm.toFixed(1);
      const updatedDistance = +roundOff;
      distance2 = updatedDistance + 2;
    }

    newUpdatedDistance = updatedDistance + distance1 + distance2;
    setSubmitOrder(true);
    setKilometers(newUpdatedDistance);
    setButtonLoader(false);

    if (newUpdatedDistance <= 3) {
      if (solo) {
        prices = 39;
      } else {
        prices = 60;
      }
      const securePackageValue = +secPack.current.value / 100;
      setSecureValue(securePackageValue);
      const newPrice = securePackageValue + prices + initial;
      return setPrice(Math.round(newPrice));
    } else {
      if (solo) {
        const extraPrice = newUpdatedDistance - 3;
        const securePackageValue = +secPack.current.value / 100;
        setSecureValue(securePackageValue);

        prices =
          39 + securePackageValue + initial + extraPrice * myArray[0].pricing;
        return setPrice(Math.round(prices));
      } else {
        const extraPrice = newUpdatedDistance - 3;
        const securePackageValue = +secPack.current.value / 100;
        setSecureValue(securePackageValue);
        prices =
          60 + securePackageValue + initial + extraPrice * myArray[0].duo;
        return setPrice(Math.round(prices));
      }
    }
  }

  const extractedCoupons = myCoupon.map((obj) => obj.coupon);
  const availCoupon = () => {
    if (coupon.current.value.trim().length === 0) {
      return toast.error("Enter Coupon value");
    }
    if (extractedCoupons.includes(coupon.current.value)) {
      setPrice(
        Math.round(price - (price * coupon.current.value.slice(-2)) / 100)
      );
      setButtonDisabled(true);
      setNotification("Coupon availed successfully!");
    } else {
      setNotification("Coupon not valid.");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const uniqueId = uuidv4();
    const orderId = uniqueId.slice(-5).toUpperCase();
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    const time = today.getHours() + ":" + today.getMinutes();
    const dateTime = date + " " + time;
    if (
      pickNo.current.value.length < 10 ||
      dropNo.current.value.length < 10 ||
      pickNo.current.value.length > 10 ||
      dropNo.current.value.length > 10
    ) {
      toast.error("Please provide Correct Number");
    } else {
      const state = {
        orderId: orderId,
        rider: rider.current,
        // weight: selectedValueRef.current,
        pickAdd: pickAdd.current.value,
        pickNo: pickNo.current.value,
        pickRem: pickRem.current.value,
        dropAdd: dropAdd.current.value,
        dropNo: dropNo.current.value,
        dropRem: dropRem.current.value,
        dropAdd1: multipleDropLocation1 ? dropAdd1.current.value : "",
        dropNo1: multipleDropLocation1 ? dropNo1.current.value : "",
        dropRem1: multipleDropLocation1 ? dropRem1.current.value : "",
        dropAdd2: multipleDropLocation2 ? dropAdd2.current.value : "",
        dropNo2: multipleDropLocation2 ? dropNo2.current.value : "",
        dropRem2: multipleDropLocation2 ? dropRem2.current.value : "",
        secPack: secPack.current.value,
        packRef: packRef.current.value,
        // payment: payment.current,
        status: "Placed",
        pickupTime: "",
        price: price,
        deliveryBoy: "Not Assigned",
        kilometers: kilometers,
        // access: "Open",
        deliveryEmail: "",
        userEmail: user,
        dateTime: dateTime,
        version: "user",
        // franchise: "none",
        // franchiseDistance: franchise,
        fcm_Token: "",
        cod: "",
        // clientDateTime: OrderDateTime,
      };
      setSubmitButtonDisabled(true);
      fire.child(`activeOrders`).push(state, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Order Placed Successfully");
        }
      });
      setTimeout(() => navigate("/history"), 1000);
    }
  };

  const soloHandler = () => {
    setSolo(true);
    setDuo(false);
    setSubmitOrder(false);
    rider.current = "Express Rider";
  };
  const duoHandler = () => {
    setDuo(true);
    setSolo(false);
    setSubmitOrder(false);
    rider.current = "Elite Rider";
  };

  // const fromPickup = () => {
  //   setPickup(true);
  //   setDrop(false);
  //   payment.current = "From Pickup";
  // };
  // const fromDrop = () => {
  //   setDrop(true);
  //   setPickup(false);
  //   payment.current = "From Drop";
  // };

  const clearCoupon = () => {
    if (coupon.current.value.trim().length === 0) {
      toast.error("Enter Coupon Value");
    } else {
      coupon.current.value = "";
      calculateRoute();
      setButtonDisabled(false);
      setNotification("");
    }
  };

  return (
    <>
      {!loggedIn && (
        <h1 className={classes.notLoggedin}>
          Complete Email Verification and Login To Place the Order
        </h1>
      )}
      {loggedIn && (
        <div className={classes.order}>
          <div className={classes.notification}>
            {myArray.map((id) => {
              return (
                <div key={id.pricing}>
                  <h2>{id.notification}</h2>
                  <h2>{id.rainNotification}</h2>
                </div>
              );
            })}
          </div>
          <form onSubmit={submitHandler} className={classes.courierDetails}>
            <div className={classes.weight}>
              {/* <h3>
                {" "}
                Select Weight <span className={classes.required}>*</span>
              </h3> */}
              {/* <div
                className={classes.weightClass}
                onClick={weightContainerClick}
              >
                <p
                  style={{
                    border: clicked0 ? "1px solid red" : "1px solid silver",
                  }}
                  onClick={weight1kg}
                >
                  Up to 1 Kg
                </p>
                <p
                  style={{
                    border: clicked1 ? "1px solid red" : "1px solid silver",
                  }}
                  onClick={weight5kg}
                >
                  Up to 5 Kg
                </p>
                <p
                  style={{
                    border: clicked2 ? "1px solid red" : "1px solid silver",
                  }}
                  onClick={weight10kg}
                >
                  Up to 10 Kg
                </p>
              </div> */}
              <h3>
                {" "}
                Select Rider <span className={classes.required}>*</span>
              </h3>
              <div className={classes.riderContainer}>
                <button
                  type="button"
                  onClick={soloHandler}
                  style={{
                    border: solo ? "1px solid red" : "1px solid silver",
                  }}
                >
                  <b>Express Rider</b>
                  <br />
                  (For Cupcakes & Other Products)
                </button>{" "}
                <button
                  type="button"
                  onClick={duoHandler}
                  style={{ border: duo ? "1px solid red" : "1px solid silver" }}
                >
                  <b>Elite Rider</b>
                  <br />
                  (For Cakes & Hamper Products)
                </button>
              </div>
            </div>

            <div className={classes.addresses}>
              <div className={classes.pickUp}>
                <label className={classes.pick}>
                  Pick up Address <span className={classes.required}>*</span>
                </label>
                <Autocomplete>
                  <input
                    type="text"
                    id="text"
                    placeholder="Type your Pickup Address"
                    className={classes.add}
                    name="pickAdd"
                    ref={pickAdd}
                    onChange={changePickupAddress}
                    required
                    // value={pickAdd || ""}
                    // onChange={handleInputChange}
                  />
                </Autocomplete>
                <label className={classes.pick}>
                  10-digit Mobile Number{" "}
                  <span className={classes.required}>*</span>
                </label>
                <div className={classes.numberContainer}>
                  <span>+91</span>
                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="pickNo"
                    ref={pickNo}
                    required
                    onKeyDown={numberLength}
                  />
                </div>
                <input
                  type="text"
                  id="text"
                  placeholder="Landmark or any instructions for Pickup"
                  className={classes.remarks}
                  name="pickRem"
                  ref={pickRem}
                />
              </div>

              <div className={classes.drop}>
                <label className={classes.pick}>
                  Delivery Address <span className={classes.required}>*</span>
                </label>
                <Autocomplete>
                  <input
                    type="text"
                    id="text"
                    placeholder="Type your Delivery Address"
                    className={classes.add}
                    name="dropAdd"
                    required
                    ref={dropAdd}
                    onChange={changePickupAddress}
                  />
                </Autocomplete>
                <label className={classes.pick}>
                  10-digit Mobile Number{" "}
                  <span className={classes.required}>*</span>
                </label>
                <div className={classes.numberContainer}>
                  <span>+91</span>
                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="dropNo"
                    ref={dropNo}
                    required
                    onKeyDown={numberLength}
                  />
                </div>
                <input
                  type="text"
                  id="text"
                  placeholder="Name and Landmark for Delivery"
                  className={classes.remarks}
                  name="dropRem"
                  ref={dropRem}
                />
                <div className={classes.dropLocationContainer}>
                  <div
                    className={classes.dropLocation}
                    onClick={() => {
                      setMultipleDropLocation1(true);
                      setSubmitOrder(false);
                    }}
                  >
                    &#8853; Address
                  </div>
                </div>
              </div>

              {multipleDropLocation1 && (
                <div className={classes.drop}>
                  <div className={classes.removeDropLocationContainer}>
                    <div>
                      <label className={classes.pick}>
                        Delivery Address{" "}
                        <span className={classes.required}>*</span>
                      </label>
                    </div>
                    <div
                      className={classes.removeDropLocation}
                      onClick={() => {
                        setMultipleDropLocation1(false);
                        setMultipleDropLocation2(false);
                        setSubmitOrder(false);
                      }}
                    >
                      &#10754; Remove{" "}
                    </div>
                  </div>

                  <Autocomplete>
                    <input
                      type="text"
                      id="text"
                      placeholder="Type your Delivery Address"
                      className={classes.add}
                      name="dropAdd"
                      required
                      ref={dropAdd1}
                      onChange={changePickupAddress}
                    />
                  </Autocomplete>
                  <label className={classes.pick}>
                    10-digit Mobile Number{" "}
                    <span className={classes.required}>*</span>
                  </label>
                  <div className={classes.numberContainer}>
                    <span>+91</span>
                    <input
                      type="number"
                      id="number"
                      className={classes.number}
                      name="dropNo"
                      ref={dropNo1}
                      required
                      onKeyDown={numberLength}
                    />
                  </div>
                  <input
                    type="text"
                    id="text"
                    placeholder="Name and Landmark for Delivery"
                    className={classes.remarks}
                    name="dropRem"
                    ref={dropRem1}
                  />
                  <div className={classes.dropLocationContainer}>
                    <div
                      className={classes.dropLocation}
                      onClick={() => {
                        setMultipleDropLocation2(true);
                        setSubmitOrder(false);
                      }}
                    >
                      &#8853; Address
                    </div>
                  </div>
                </div>
              )}
              {multipleDropLocation2 && (
                <div className={classes.drop}>
                  <div className={classes.removeDropLocationContainer}>
                    <div>
                      <label className={classes.pick}>
                        Delivery Address{" "}
                        <span className={classes.required}>*</span>
                      </label>
                    </div>
                    <div
                      className={classes.removeDropLocation}
                      onClick={() => {
                        setMultipleDropLocation2(false);
                        setSubmitOrder(false);
                      }}
                    >
                      &#10754; Remove{" "}
                    </div>
                  </div>
                  <Autocomplete>
                    <input
                      type="text"
                      id="text"
                      placeholder="Type your Delivery Address"
                      className={classes.add}
                      name="dropAdd"
                      required
                      ref={dropAdd2}
                      onChange={changePickupAddress}
                    />
                  </Autocomplete>
                  <label className={classes.pick}>
                    10-digit Mobile Number{" "}
                    <span className={classes.required}>*</span>
                  </label>
                  <div className={classes.numberContainer}>
                    <span>+91</span>
                    <input
                      type="number"
                      id="number"
                      className={classes.number}
                      name="dropNo"
                      ref={dropNo2}
                      required
                      onKeyDown={numberLength}
                    />
                  </div>
                  <input
                    type="text"
                    id="text"
                    placeholder="Name and Landmark for Delivery"
                    className={classes.remarks}
                    name="dropRem"
                    ref={dropRem2}
                  />
                </div>
              )}

              <div className={classes.packageType}>
                <label>
                  Type of Parcel <span className={classes.required}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="What are you sending?"
                  name="packRef"
                  ref={packRef}
                  required
                  value={clicked || ""}
                  onChange={changeClick}
                />
                <p onClick={checkClick}>Docs</p>{" "}
                <p onClick={checkClick}>Food</p>{" "}
                <p onClick={checkClick}>Meds</p>
              </div>

              <div className={classes.secureParcel}>
                <label>
                  Secure Your Parcel<span className={classes.required}>*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="number"
                  placeholder="Don't wanna secure? Enter 0 as value"
                  name="secPack"
                  ref={secPack}
                  required
                  onChange={changePickupAddress}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
                <p>
                  Rs {secureValue}: Additional Charges for Securing your Package
                </p>
                <p>
                  Upto 60% of the mentioned amount will be returned after
                  securing the parcel. (Max - Rs 5000/-)
                </p>
              </div>

              {/* <div className={classes.payment}>
                <label>
                  Payment Type: <b>Cash and UPI</b>
                </label>

                <div className={classes.cashPickup}>
                  <label>Collect Payment at - </label>
                  <br />
                  <button
                    type="button"
                    onClick={fromPickup}
                    style={{
                      border: pickup ? "1px solid red" : "1px solid silver",
                    }}
                  >
                    Pickup
                  </button>
                  <button
                    type="button"
                    onClick={fromDrop}
                    style={{
                      border: drop ? "1px solid red" : "1px solid silver",
                    }}
                  >
                    Drop
                  </button>
                </div>
              </div> */}
              <div className={classes.coupons}>
                <label>Enter Coupon: </label>
                <input type="text" placeholder="Enter Coupon" ref={coupon} />
                <span className={classes.clear} onClick={clearCoupon}>
                  <FaTimes />
                </span>{" "}
                <button
                  type="button"
                  onClick={availCoupon}
                  disabled={isButtonDisabled}
                >
                  Avail Coupon
                </button>
                {notification && <p>{notification}</p>}
              </div>
              <div className={classes.total}>
                <h2>Total: {price}</h2>
                <button type="button" onClick={calculateRoute}>
                  {buttonLoader ? "Loading..." : "See Price"}
                </button>
                {submitOrder && (
                  <button type="submit" disabled={submitButtonDisabled}>
                    Submit Order
                  </button>
                )}{" "}
              </div>
            </div>
          </form>
        </div>
      )}{" "}
      <Footer />
    </>
  );
};

export default Order;
