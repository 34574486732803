import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ForgetPassword from "./Pages/Login/ForgetPassword";
import Navbar from "./Components/Navbar/Navbar";
import Contact from "./Pages/Contact/Contact";
import Order from "./Pages/Order/Order";
import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Print from "./Pages/Order/Print";
import Privacy from "./Pages/Privacy/Privacy";
import Community from "./Pages/Privacy/Community";
import { useSelector } from "react-redux";
import Welcome from "./Pages/Order/Welcome";
import Refund from "./Pages/Privacy/Refund";
import Disclaimer from "./Pages/Privacy/Disclaimer";

const App = () => {
  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);

  return (
    <>
      <Navbar />
      <ToastContainer position="top-center" />

      <main>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/order" element={<Order />} />
          <Route path="/history" element={loggedIn ? <Welcome /> : <Home />} />
          <Route path="/print" element={loggedIn ? <Print /> : <Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/community" element={<Community />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </>
  );
};

export default App;
