import React, { useState, useEffect } from "react";
import classes from "./Order.module.css";
import { fire } from "../Login/Google";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loginActions } from "../../Store/login";

const AllOrders = React.forwardRef((props, ref) => {
  const [data, setData] = useState({});
  const [activeData, setActiveData] = useState({});
  const user = useSelector((state) => state.loggedIn.username);
  const [activeOrders, setActiveOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    // console.log(userPresent);
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  useEffect(() => {
    fire.child("allOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
        toast.error("No Data found");
      }
    });
    return () => {
      setData({});
    };
  }, []);

  useEffect(() => {
    const filteredOrder = Object.values(data).filter((filterOrder) => {
      return filterOrder.userEmail === user;
    });
    setDeliveredOrders(filteredOrder);
  }, [data, user]);

  useEffect(() => {
    fire.child("activeOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setActiveData({ ...snapshot.val() });
      } else {
        setActiveData({});
        toast.error("No Data found");
      }
    });
    return () => {
      setActiveData({});
    };
  }, []);

  useEffect(() => {
    const filteredOrder = Object.values(activeData).filter((filterOrder) => {
      return filterOrder.userEmail === user;
    });
    setActiveOrders(filteredOrder);
  }, [activeData, user]);

  const totalOrders = [...deliveredOrders, ...activeOrders];

  // function truncate(str, n) {
  //   return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  // }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.table} ref={ref}>
          <table className={classes.styledTable}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Date&Time</th>
                <th>Pickup Address</th>
                <th>Pickup No</th>
                <th>Drop Address</th>
                <th>Drop No</th>
                <th>Secure Package</th>
                <th>Order Status</th>
                <th>Price</th>
              </tr>
            </thead>

            <tbody>
              {totalOrders.map((id, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{id.dateTime}</td>
                    <td>{id.pickAdd}</td>
                    <td>{id.pickNo}</td>
                    <td>{id.dropAdd}</td>
                    <td>{id.dropNo}</td>
                    <td>{id.secPack}</td>
                    <td>{id.status}</td>
                    <td>{id.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
});

export default AllOrders;
