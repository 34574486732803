import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";

const Types = (props) => {
  const navigate = useNavigate();
  const goToOrderPage = () => {
    navigate("/order");
  };
  return (
    <div className={classes.imgContainer}>
      <img
        src={props.image}
        onClick={goToOrderPage}
        loading="lazy"
        alt="Work"
      />
    </div>
  );
};

export default Types;
