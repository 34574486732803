import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./Footer.module.css";
import fb from "../../Assets/facebook.png";
import insta from "../../Assets/insta.png";
import yt from "../../Assets/yt.png";

const Footer = () => {
  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);
  const goToHistoryPage = loggedIn ? "/history" : "/login";

  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerElements}>
          <div className={classes.logo}>
            <Link to="/">
              <div className={classes.logoElements}>Chatakk</div>
            </Link>
            <div className={classes.socials}>
              <a
                href="https://www.facebook.com/profile.php?id=100090713358709"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fb} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/chatakkdelivery/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} alt="Instagram" />
              </a>
              <a
                href="https://www.youtube.com/@Chatakkdelivery-ChatakkSeBhej"
                target="_blank"
                rel="noreferrer"
              >
                <img src={yt} alt="Youtube" />
              </a>
            </div>
          </div>
          <div className={classes.items1}>
            <Link to="/privacy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/disclaimer">
              <p>Disclaimers and Dispute Resolution</p>
            </Link>
            <Link to="refund">
              <p>Refund and Cancellation</p>
            </Link>
            <Link to="/community">
              <p>Community Guidelines</p>
            </Link>
          </div>
          <div className={classes.items2}>
            <Link to="/contact">
              <p>For Business</p>
            </Link>

            <Link to="/contact">
              <p>Contact Us</p>
            </Link>
            <Link to={goToHistoryPage}>
              <p>Track Orders</p>
            </Link>

            <p>Terms and Conditions</p>
          </div>
        </div>
        <div className={classes.copyright}>
          &copy; 2022-2023 All Rights Reserved
        </div>
      </div>{" "}
    </>
  );
};

export default Footer;
