import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classes from "./Home.module.css";
import courierImage from "../../Assets/heroImg.webp";
import green from "../../Assets/green.webp";
import Types from "./Types";
import { allTypes } from "./allTypes";
import Faq from "../../Components/FAQ/Faq";
import Footer from "../../Components/Footer/Footer";
import { FaTimes } from "react-icons/fa";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { toast } from "react-toastify";
import "../../App.css";
import { loginActions } from "../../Store/login";
import { fire } from "../Login/Google";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  const [libraries] = useState(["places"]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);
  const goToPage = loggedIn ? "/order" : "/login";
  const [myArray, setMyArray] = useState([]);
  const [userData, setUserData] = useState({});
  const [distance, setDistance] = useState("0 km");
  const [price, setPrice] = useState(0);
  const [solo, setSolo] = useState(false);
  const [duo, setDuo] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const originRef = useRef();
  const destiantionRef = useRef();

  useEffect(() => {
    fire.child("dynamicUpdate").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setUserData({ ...snapshot.val() });
      } else {
        setUserData({});
      }
    });
    return () => {
      setUserData({});
    };
  }, []);

  useEffect(() => {
    const filteredUser = Object.values(userData).filter((filterOrder) => {
      return filterOrder.price !== 100;
    });
    setMyArray(filteredUser);
  }, [userData]);

  if (!isLoaded) {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      return toast.error("Enter Address Values");
    }
    if (!solo && !duo) {
      return toast.error("Select Solo or Duo Rider Option");
    }

    setButtonLoader(true);

    let initial = 0;
    let price;
    const shortestDistance = [];
    const pickupShortDistance = [];

    function findLowestNumber(arr) {
      if (arr.length === 0) {
        return undefined;
      }

      let lowest = arr[0];

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] < lowest) {
          lowest = arr[i];
        }
      }

      return lowest;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    //  Pickup Point Distance
    const initialResults = await directionsService.route({
      origin:
        "16/h/3, Bright St, Park Circus, Ballygunge, Kolkata, West Bengal 700017",
      destination: originRef.current.value,
      provideRouteAlternatives: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    // console.log(initialResults);
    for (let i = 0; i < initialResults.routes.length; i++) {
      pickupShortDistance.push(initialResults.routes[i].legs[0].distance.value);
    }
    // console.log(pickupShortDistance);
    const pickLowestNumber = findLowestNumber(pickupShortDistance);
    const pickMToKm = pickLowestNumber / 1000;
    const initialUpdatedDistance = pickMToKm.toFixed(1);

    if (initialUpdatedDistance > 3 && initialUpdatedDistance <= 5.3) {
      initial = 7;
    } else if (initialUpdatedDistance > 5.3 && initialUpdatedDistance <= 7.3) {
      initial = 12;
    } else if (initialUpdatedDistance > 7.3 && initialUpdatedDistance <= 10.3) {
      initial = 18;
    } else if (
      initialUpdatedDistance > 10.3 &&
      initialUpdatedDistance <= 15.3
    ) {
      initial = 25;
    } else if (
      initialUpdatedDistance > 15.3 &&
      initialUpdatedDistance <= 20.3
    ) {
      initial = 40;
    } else if (initialUpdatedDistance > 20.3) {
      initial = 50;
    }

    // Distance Calculator

    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      provideRouteAlternatives: true,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    for (let i = 0; i < results.routes.length; i++) {
      shortestDistance.push(results.routes[i].legs[0].distance.value);
    }

    const lowestNumber = findLowestNumber(shortestDistance);
    const mToKm = lowestNumber / 1000;
    const roundOff = mToKm.toFixed(1);
    setDistance(`${roundOff} km`);
    const updatedDistance = +roundOff;

    const newUpdatedDistance = updatedDistance;

    setButtonLoader(false);

    if (newUpdatedDistance <= 3) {
      if (solo) {
        price = 39 + initial;
      } else {
        price = 60 + initial;
      }

      return setPrice(Math.round(price));
    } else {
      if (solo) {
        const extraPrice = newUpdatedDistance - 3;
        price = 39 + initial + extraPrice * myArray[0].pricing;
        return setPrice(Math.round(price));
      } else {
        const extraPrice = newUpdatedDistance - 3;
        price = 60 + initial + extraPrice * myArray[0].duo;
        return setPrice(Math.round(price));
      }
    }
  }

  function clearRoute() {
    setDistance("0 km");
    setPrice(0);
    originRef.current.value = "";
    destiantionRef.current.value = "";
  }
  const goToOrderPage = () => {
    navigate("/order");
  };
  const bookACall = () => {
    window.location.href = "tel:8100453171";
  };

  const soloHandler = () => {
    setSolo(true);
    setDuo(false);
  };
  const duoHandler = () => {
    setDuo(true);
    setSolo(false);
  };

  return (
    <>
      <div className={classes.hero}>
        <div className={classes.heroCont}>
          <div className={classes.heroCont1}>
            <div className={classes.heading}>
              <h1>
                Efficient Same-Day Courier and Parcel Delivery Services in
                Kolkata with Top-Tier Security
              </h1>
            </div>
            <div className={classes.para}>
              <p>
                Same-Day Courier Services Across Kolkata - Send Your Parcels
                with Ease!
              </p>
            </div>
            <div className={classes.distance}>
              {/* <form> */}
              <Autocomplete>
                <input type="text" placeholder="Origin" ref={originRef} />
              </Autocomplete>
              <span className={classes.clear} onClick={clearRoute}>
                <FaTimes />
              </span>
              <Autocomplete>
                <input
                  type="text"
                  placeholder="Destination"
                  className={classes.destination}
                  ref={destiantionRef}
                />
              </Autocomplete>
              {/* <input type="text" placeholder="Pick-up" /> */}
              {/* <input type="text" placeholder="Pick-up" /> */}
              <div className={classes.riderContainer}>
                <button
                  onClick={soloHandler}
                  style={{
                    border: solo ? "1px solid red" : "1px solid silver",
                  }}
                >
                  Express Rider
                </button>{" "}
                <button
                  onClick={duoHandler}
                  style={{ border: duo ? "1px solid red" : "1px solid silver" }}
                >
                  Elite Rider
                </button>
              </div>
              <button onClick={calculateRoute}>
                {buttonLoader ? "Loading..." : "Calculate the Price"}
              </button>
              <div className={classes.price}>
                <p>Distance: {distance}</p>
                <p>Price: Rs {price}</p>
              </div>
              <p>To Place the Order, just </p>

              <div className={classes.gotoLogin}>
                <button onClick={goToOrderPage}>Order</button>{" "}
                <button onClick={bookACall}>Call Us</button>
              </div>
              {/* </form> */}
            </div>
          </div>
          <div className={classes.heroCont2}>
            {/* <link rel="preload" as="image" href={courierImage} /> */}
            <img src={courierImage} alt="Courier with Price" />
          </div>
        </div>
      </div>

      {/*  */}
      {/* Delivery Types Section */}
      {/*  */}

      <div className={classes.deliveryTypes}>
        <div className={classes.allTypes}>
          <h1>
            For any type of Delivery, <br /> We know how to help
          </h1>
          <div className={classes.types}>
            {allTypes.map((working) => (
              <Types key={working.key} image={working.image} />
            ))}
          </div>
          <h1>
            Delivering Packages all <br /> over Kolkata
          </h1>
        </div>
      </div>

      {/* WhatsApp */}

      <div className={classes.whatsapp}>
        <div className={classes.deliver}>
          <h2>Tell us Where to Deliver</h2>
          <p>
            Create an account and keep all your deliveries in one place and get
            your delivery Chatakk
          </p>
        </div>
        <div className={classes.deliverConnect}>
          <a href="http://wa.me/+918961890709" target="blank">
            <button>WhatsApp Us</button>
          </a>
          <Link to={goToPage}>
            <button>Chatakk Now</button>
          </Link>
        </div>
      </div>

      {/* Payment Methods */}

      {/* <div className={classes.payment}>
        <h2>Payment Methods</h2>
        <div className={classes.paymentImg}>
          <img src={cash} alt="Cash" />
        </div>
      </div> */}

      {/* GO GREEN SECTION */}

      <div className={classes.goGreen}>
        <div className={classes.greenContainer}>
          <div className={classes.heading}>
            <h2>
              Make a Difference with Chatakk: Join the Go Green Initiative
            </h2>
            <h4>
              Our Kolkata-based startup provides fast, efficient, and
              environmentally conscious delivery services, using E-Bikes and
              Cycles with optimized delivery routes.
              <br />
              <br />
              As an Eco-Friendly delivery service, we aim to minimize our carbon
              footprint while offering reliable delivery services. Our mission
              is to revolutionize same day delivery industry by adopting
              sustainable practices.
              <br />
              <br />
              Join us to meet humanity's needs without causing harm to the
              environment.
            </h4>
          </div>
          <div className={classes.greenImage}>
            <img src={green} alt="E-bikes" />
          </div>
        </div>
      </div>

      {/* FAQ Section */}

      <Faq />

      {/* Footer */}

      <Footer />
    </>
  );
};

export default Home;
